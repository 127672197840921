<template>
  <b-card style="box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);" no-body>
    <b-card-body style="min-height: 330px;">
      <h1 style="font-size: 20px; margin-bottom: 25px; font-weight: 600; display: flex; flex-direction: row; align-items: center;">
        Inquiries
        <span style="background-color: rgb(23, 92, 142); margin-left: 10px; padding: 5px; border-radius: 20px; color: white; font-weight: 400; height: 25px; min-width: 25px; text-align: center; font-size: 14px;">{{ inquiriesCount }}</span>
      </h1>
      <table class="table">
            <tbody>
              <tr v-b-tooltip.hover.left :title="inquiry.message" class="small" v-for="(inquiry,index) in inquiriesToDisplay" :key="index">
                <td class="d-none d-md-table-cell" width="25" style="padding-left: 10px;"><b-icon-chat-fill style="width: 20px; height: 20px; margin-right: 5px; color: #c5c5c5;"></b-icon-chat-fill></td>
                <td class=""><strong>{{ inquiry.name }}</strong></td>
                <td class="d-none d-md-table-cell">{{ inquiry.role }}</td>
                <td class="">{{ inquiry.dealership_name }}</td>
                <td class="d-none d-md-table-cell">{{ inquiry.email }}</td>
                <td class="">{{ inquiry.phone }}</td>
                <td class="text-right d-none d-md-table-cell">{{ inquiry.create_date }}</td>
              </tr>
            </tbody>
          </table>
    </b-card-body>
  </b-card>
</template>

<script>
import axios from "axios";
export default {
  
  name: "inquiries-dashboard-widget",

  components: {
    
  },

  created () {
    this.getInquiries();
  },

  data() {
    return {
      inquiries: {},
      showLessInquiries: true,
      inquiriesVisible: 15,
    }
  },

  methods: {

    getInquiries(){
      this.$store.commit('generateApiCreds');
      axios
        .get(process.env.VUE_APP_API_PATH + 'inquiries', {       
          auth: {
            username: this.$store.state.auth.apiUsername,
            password: this.$store.state.auth.apiPassword,
          }
        })
        .then(resp => {
          this.inquiries = resp.data;
        })
        // .catch(err => {
        //   alert('We have an error. Please contact support.' + err)
        //   console.log(err);
        // });
    },

    toggleInquiry(index) {
      var current = this.inquiriesToDisplay[index].active;
      this.$set(this.inquiriesToDisplay[index], 'active', !current);
    },

  },

  computed: {
    inquiriesToDisplay: function() {
      let data = {};
      if(this.showLessInquiries) {
        for (let [key, value] of Object.entries(this.inquiries)) {
          if(key < this.inquiriesVisible ){
            data[key] = value;
            data[key].active = false;
          }
        }
      } else {
        return this.inquiries;
      }
      return data;
    },

    inquiriesCount: function() {
      return this.inquiries.length;
    },
  }

};

</script>

<style scoped>


</style>
