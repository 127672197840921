import { render, staticRenderFns } from "./CategoriesDashboardWidget.vue?vue&type=template&id=24af5940&scoped=true&"
import script from "./CategoriesDashboardWidget.vue?vue&type=script&lang=js&"
export * from "./CategoriesDashboardWidget.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24af5940",
  null
  
)

export default component.exports