<template>
  <b-card style="box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);" no-body>
    <b-card-body style="min-height: 330px;">
      <h1 style="font-size: 20px; margin-bottom: 25px; font-weight: 600; display: flex; flex-direction: row; align-items: center;">
        Activity Log
      </h1>
      <table class="table table-hover">
        <tbody>
          <tr class="small" v-for="log in logsToDisplay" :key="log.log_id">

            <td width="20" v-if="log.status == 'success'" class=""><b-icon-circle-fill style="color: green;"></b-icon-circle-fill></td>
            <td width="20" v-else class=""><b-icon-circle-fill style="color: red;"></b-icon-circle-fill></td>

            <td class="">{{ log.ip_address }}</td>
            <td class="">{{ log.description | capitalize }}</td>

            <td v-if="log.user_id" class="d-none d-lg-table-cell">User id: {{ log.user_id }}</td>
            <td v-else class="">Public</td>

            <td class="text-right d-none d-lg-table-cell">{{ log.create_date | moment("calendar") }}</td>
          </tr>
        </tbody>
      </table>
    </b-card-body>
  </b-card>
</template>

<script>
import axios from "axios";
export default {
  
  name: "logs-dashboard-widget",

  components: {
    
  },

  created () {
    this.getLogs();
  },

  data() {
    return {
      logs: {},
      showLessLogs: true,
      logsVisible: 50,
    }
  },

  methods: {

    getLogs(){
      this.$store.commit('generateApiCreds');
      axios
        .get(process.env.VUE_APP_API_PATH + 'logs', {       
          auth: {
            username: this.$store.state.auth.apiUsername,
            password: this.$store.state.auth.apiPassword,
          }
        })
        .then(resp => {
          this.logs = resp.data;
        })
        // .catch(err => {
        //   alert('We have an error. Please contact support.' + err)
        //   console.log(err);
        // });
    },

  },

  computed: {
    logsToDisplay: function() {
      let data = {};
      if(this.showLessLogs) {
        for (let [key, value] of Object.entries(this.logs)) {
          if(key < this.logsVisible ){
            data[key] = value;
          }
        }
      } else {
        return this.logs;
      }
      return data;
    },
  }

};

</script>

<style scoped>


</style>
