<template>
  <b-card style="box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);" no-body>
    <b-card-body style="min-height: 300px;">
      <b-icon-plus @click="newVendor()" class="plus-icon"></b-icon-plus>
      <h1 style="font-size: 20px; margin-bottom: 25px; font-weight: 600;">Vendors 
        <span style="background-color: rgb(23, 92, 142); margin-left: 10px; padding: 5px; border-radius: 20px; color: white; font-weight: 400; height: 25px; min-width: 25px; text-align: center; font-size: 14px;">{{ vendorsCount }}</span>
      </h1>
      <table class="table">
            <tbody>
              <tr class="small" v-for="vendor in vendors" :key="vendor.vendor_id">
                <td class="">{{ vendor.display_name }} 
                  <b-badge v-show="vendor.active == false" class="ml-2" variant="secondary">Inactive</b-badge>
                </td>
                <td class="">
                  <router-link class="float-right small p-1 btn-dashboard" :to="{ name: 'Edit Vendor', params: { vendorId: vendor.vendor_id }}"><b-icon-pen></b-icon-pen> Edit</router-link>
                </td>
              </tr>
            </tbody>
          </table>
    </b-card-body>
  </b-card>
</template>

<script>
import axios from "axios";
export default {
  
  name: "vendors-dashboard-widget",

  components: {
    
  },

  created () {
    this.getVendors();
  },

  data() {
    return {
      vendors: {},
    }
  },

  methods: {

    getVendors(){
      this.$store.commit('generateApiCreds');
      axios
        .get(process.env.VUE_APP_API_PATH + 'vendors', {       
          auth: {
            username: this.$store.state.auth.apiUsername,
            password: this.$store.state.auth.apiPassword,
          }
        })
        .then(resp => {
          this.vendors = resp.data;
        })
        // .catch(err => {
        //   alert('We have an error. Please contact support.' + err)
        //   console.log(err);
        // });
    },

    newVendor() {
      this.$router.push('/vendors/new');
    },

    editVendor(vendorId){
      alert('edit vendor clicked' + vendorId);
    },
  },

  computed: {
    vendorsCount: function() {
      return this.vendors.length;
    },
  },

};

</script>

<style scoped>


</style>
