<template>
  <b-card style="box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);" no-body>
    <b-card-body style="min-height: 300px;">
      <b-icon-plus @click="newUser()" class="plus-icon"></b-icon-plus>
      <h1 style="font-size: 20px; margin-bottom: 25px; font-weight: 600;">Users 
        <span style="background-color: rgb(23, 92, 142); margin-left: 10px; padding: 5px; border-radius: 20px; color: white; font-weight: 400; height: 25px; min-width: 25px; text-align: center; font-size: 14px;">{{ usersCount }}</span>
      </h1>
      <table class="table">
            <tbody>
              <tr class="small" v-for="user in users" :key="user.user_id">
                <td class="">{{ user.first_name }} {{ user.last_name }}</td>
                <td class="d-none d-lg-block">{{ user.email }}</td>
                <td v-if="user.last_login" class="d-none d-md-table-cell">Logged in {{ user.last_login }}</td>
                <td v-else class="d-none d-md-table-cell">No login history</td>
                <td>
                  <router-link class="float-right small p-1 btn-dashboard" style="width: 45px;" :to="{ name: 'Edit User', params: { userId: user.user_id }}"><b-icon-pen></b-icon-pen> Edit</router-link>
                </td>
              </tr>
            </tbody>
          </table>
    </b-card-body>
  </b-card>
</template>

<script>
import axios from "axios";

export default {
  
  name: "users-dashboard-widget",

  components: {
    
  },

  created () {
    this.getUsers();
  },

  data() {
    return {
      users: {},
    }
  },

  methods: {

    getUsers(){
      this.$store.commit('generateApiCreds');
      axios
        .get(process.env.VUE_APP_API_PATH + 'users', {       
          auth: {
            username: this.$store.state.auth.apiUsername,
            password: this.$store.state.auth.apiPassword,
          }
        })
        .then(resp => {
          this.users = resp.data;
        })
        // .catch(err => {
        //   alert('We have an error. Please contact support.' + err)
        //   console.log(err);
        // });
    },

    newUser() {
      this.$router.push('/users/new');
    },

    editUser(userId){
      this.$router.push('/users/edit/' + userId );
    },
  },

  computed: {
    usersCount() {
      return this.users.length;
    }
  },

};

</script>

<style scoped>


</style>
