<template>
  <b-card style="box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);" no-body>
    <b-card-body style="min-height: 150px;">
      <b-icon-plus @click="newCategory()" class="plus-icon"></b-icon-plus>
      <h1 style="font-size: 20px; margin-bottom: 25px; font-weight: 600;">Categories 
        <span style="background-color: rgb(23, 92, 142); margin-left: 10px; padding: 5px; border-radius: 20px; color: white; font-weight: 400; height: 25px; min-width: 25px; text-align: center; font-size: 14px;">{{ categoriesCount }}</span>
      </h1>
      <table class="table">
            <tbody>
              <tr class="small" v-for="category in categories" :key="category.category_id">
                <td width="260" class="">{{ category.display_name }} 
                  <b-badge v-show="category.active == false" class="ml-2" variant="secondary">Inactive</b-badge>
                </td>
                <td class="">
                  <router-link class="float-right small p-1 btn-dashboard" :to="{ name: 'Edit Category', params: { categoryId: category.category_id }}"><b-icon-pen></b-icon-pen> Edit</router-link>
                </td>
              </tr>
            </tbody>
          </table>
    </b-card-body>
  </b-card>
</template>

<script>
import axios from "axios";
export default {
  
  name: "categories-dashboard-widget",

  components: {
    
  },

  created () {
    this.getCategories();
  },

  data() {
    return {
      categories: {},
    }
  },

  methods: {

    getCategories(){
      this.$store.commit('generateApiCreds');
      axios
        .get(process.env.VUE_APP_API_PATH + 'categories', {       
          auth: {
            username: this.$store.state.auth.apiUsername,
            password: this.$store.state.auth.apiPassword,
          }
        })
        .then(resp => {
          this.categories = resp.data;
        })
        // .catch(err => {
        //   alert('We have an error. Please contact support.' + err)
        //   console.log(err);
        // });
    },

    newCategory() {
      this.$router.push('/categories/new');
    },

    ediCategory(categoryId){
      this.$router.push('/categories/edit/' + categoryId );
    },
  },

  computed: {
    categoriesCount: function() {
      return this.categories.length;
    },
  },

};

</script>

<style scoped>


</style>
