<template>
  <b-row style="background-color: #F5F7FA; padding-bottom: 50px;" class="d-flex justify-content-center content">
    <b-col style="max-width: 1200px;">
      <b-row>

        <b-col lg="12" class="mb-4">
          <InquiriesDashboardWidget />
        </b-col>

        <b-col lg="6" class="mb-4">
          <CategoriesDashboardWidget />
        </b-col>

        <b-col lg="6" class="mb-4">
          <VendorsDashboardWidget />
        </b-col>

        <b-col lg="12" class="mb-4">
          <UsersDashboardWidget />
        </b-col>

        <b-col lg="12" class="mb-4">
          <LogDashboardWidget />
        </b-col>

      </b-row>
    </b-col>
  </b-row>

</template>

<script>

  import UsersDashboardWidget from '@/components/dashboard/UsersDashboardWidget.vue'
  import VendorsDashboardWidget from '@/components/dashboard/VendorsDashboardWidget.vue'
  import CategoriesDashboardWidget from '@/components/dashboard/CategoriesDashboardWidget.vue'
  import InquiriesDashboardWidget from '@/components/dashboard/InquiriesDashboardWidget.vue'
  import LogDashboardWidget from '@/components/dashboard/LogDashboardWidget.vue'

  export default {

  name: 'dashboard',

  components: {
      UsersDashboardWidget,
      VendorsDashboardWidget,
      CategoriesDashboardWidget,
      InquiriesDashboardWidget,
      LogDashboardWidget,
  },

  metaInfo: {
    title: 'Dashboard'
  },

  created () {

  },

  data() {
    return {

    }    
  },

  methods: {    

  },

  computed: {
    
  }
}
</script>

<style>

.plus-icon {
  height: 40px;
  width: 40px;
  background-color: rgb(245, 247, 250);
  color: #212529;
  border-radius: 25px;
  float: right;
}

.plus-icon:hover {
  background-color: rgb(23, 92, 142);
  cursor: pointer;
  color: white;
}

.widget-item {
  text-decoration: none;
}

.widget-item:hover {
  background-color: grey;
}

.table > tbody > tr:first-child > td {
    border: none;
}

.btn-dashboard {
  color: #6c757d;
  padding: 5px;
}

.btn-dashboard:hover {
  color: white;
  border-radius: 5px;
  background-color: rgb(23, 92, 142);
  text-decoration: none;
}

.content {
  padding-top: 20px;
}

@media screen and (max-width: 768px) {

  .content {
    margin-top: 0px;
    padding-top: 40px;
  }

}

</style>
